import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/lost",
      name: "lost",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/form",
      name: "form",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/wait",
      name: "wait",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/done",
      name: "done",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
  ],
});

export default router;
